.fadeout{
/*    width: 60%;*/
    margin-bottom: 5px;
    padding-bottom: 5px;
/*    border-bottom: 3px solid #777;*/
    max-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "";
    position: relative;
}
.fadeout:before {
    content: '';
    width: 100%;
    height: 100%;    
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 50px, #ffffff);
}